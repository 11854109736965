<template>
    <page-content>

        <common-table
                ref="table"
                path="web/thirdpart/shenzhen"
                :columns="columns">

            <template slot="search">
                <a-button ghost type="primary" @click="add">新增</a-button>
            </template>

            <template slot="disable" slot-scope="{record}">
                <a-switch :checked="record.disable" size="small" @click="clickDisable(record)"></a-switch>
            </template>

            <template slot="operation" slot-scope="{record}">
                <action-edit @click="edit(record)"></action-edit>
                <action-delete @click="remove(record)"></action-delete>
            </template>

        </common-table>

        <ShenZhenEdit ref="editDrawer" @success="getList"></ShenZhenEdit>

    </page-content>

</template>
<script>
    import ShenZhenEdit from './ShenZhenEdit'

    export default {
        components: {
          ShenZhenEdit
        },
        computed: {
            columns() {
                return [
                    {
                        title: '名称',
                        dataIndex: 'name'
                    },
                    {
                        title: '设备MAC',
                        dataIndex: 'mac',
                    },
                    {
                        title: '设备Token',
                        dataIndex: 'appKey'
                    },
                    {
                        title: '操作',
                        dataIndex: 'operation',
                        width: 80,
                        scopedSlots: {customRender: 'operation'}
                    }
                ]
            }
        },
        methods: {
            add() {
                this.$refs.editDrawer.show()
            },
            edit(record) {
                this.$refs.editDrawer.show(record)
            },
            remove(record) {
                let that = this
                this.$confirm({
                    title: '是否删除该记录?',
                    content: '该操作不可撤销',
                    centered: true,
                    onOk() {
                        that.$delete('web/thirdpart/shenzhen/' + record.id).then(() => {
                            that.$message.success('删除成功')
                            that.getList()
                        })
                    }
                })
            },
            getList() {
                this.$refs.table.getData()
            }

        }
    }
</script>
<style lang="less" scoped>

</style>
